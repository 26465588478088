* {
  box-sizing:border-box;
  padding:0;
  text-decoration: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../src/assets/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../src/assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(../src/assets/fonts/MaterialIcons-Regular.woff) format('woff'),
       url(../src/assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url(../src/assets/fonts/Rubik-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: bold;
  font-weight: 700;
  src: url(../src/assets/fonts/Rubik-Bold.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
